
<template>
<div>
  <form class="">
    <div class="mb-4">
        <input type="email" v-model="data.email" name="email" @keyup.enter="submitData"
          :class="{ 'is-invalid': v$.data.email.$error }" class="form-control" placeholder="Enter email">
        
        <div v-for="(item, index) in v$.data.email.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
    </div>
    <div class="mb-4">
        <input type="password" v-model="data.password" name="password" @keyup.enter="submitData"
          :class="{ 'is-invalid':  v$.data.password.$error }" class="form-control" placeholder="Enter your password">
        
        <div v-if="v$.data.password.$error"
          class="invalid-feedback">
          <span v-if="v$.data.password.required.$message">{{
            v$.data.password.required.$message }}</span>
        </div>
    </div>
    <div class="mb-3 font-13 d-flex justify-content-between">
        <div class="form-check">
            <input v-model="data.remember" type="checkbox" id="remember" class="form-check-input">
            <label for="remember" class="form-check-label fs-base">Remember me</label>
        </div>
    </div>
    <div class="d-grid mb-4">
      <button @click.prevent="submitData()" class="btn btn-primary"  type="button">
          Sign In
      </button>
    </div>
  </form>
</div>
</template>
<script>
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: 'user-login-form',
  setup() {
    return { v$: useVuelidate() };
  },
  props:{
    oncheckout:{
      type: Boolean,
    },
  },
  validations: {
    data:{
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    }
  },
  data() {
    return {
      data: {
        email: '',
        password: '',
        remember: true
      },
    }
  },
  methods: {
    passwordVisibility(){
        this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
    },
    forgotPassword(){
      window.location =  this.absoluteUrl('/password/reset');
    },
    submitData(){
      this.v$.$validate().then(result =>{
        if (!result) return;
        this.$store.dispatch("changeLoaderValue", true)
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$http.post("/login", formData)
        .then((response)=> {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.takeAction(response.data.data)
          }
        })
      });        
    },
    takeAction(data){
      this.resetForm()
      this.$emit("takeAction", {...data})
    },
    resetForm(){
      this.data = {
        email: '',
        password: '',
        remember: true
      }
      this.v$.$reset()
    },
   },
  }
</script>

<style lang="scss">

</style>
